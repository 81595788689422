export default {
  'voucher.router.name': 'Voucher quy đổi',
  'voucher.name': 'Loại voucher',
  'voucher.title': 'Quản lý kho voucher quy đổi',
  'voucher.update': 'Cập nhật voucher',
  'voucher.create': 'Tạo voucher',
  'voucher.quantity': 'Tổng số lượng',
  'voucher.remainingQuantity': 'Số lượng đã mua',
  'voucher.usedQuantity': 'Số lượng đã sử dụng',
  'voucher.updatedAt': 'Cập nhập mới nhất',
  'voucher.buyDeadline': 'Thời hạn mua',
  'voucher.coin': 'Green Coin',
  'voucher.status': 'Trạng thái',
  'voucher.sortType': 'Loại sắp xếp',
  'voucher.businessStatus': 'Trạng thái hoạt động',
  'voucher.content': 'Nội dung',
  'voucher.delete.title': 'Xóa voucher',
  'voucher.delete.content': 'Bạn chắc chắn muốn xóa voucher này?',
  'voucher.price': 'Số điểm quy đổi',
  'voucher.amount': 'Số tiền nhận được (VNĐ)',
  'voucher.modal.amount': 'Số tiền nhận được',
  'voucher.modal.quantity': 'Số lượng',
  'validate.dateTime': 'Vui lòng nhập thời hạn mua',
  'voucher.information': 'Thông tin voucher',
  'voucher.modal.quantity.validate': 'Số lượng phải lớn hơn số lượng đã sử dụng',
  'voucher.status.operationStatus':
    '{status,select, 1 {Đang kinh doanh} 2 {Chưa kinh doanh} 3 {Kết thúc} 4 {Ngừng kinh doanh} other {Không xác định}}',

  'voucher.status.Available': 'Đang kinh doanh',
  'voucher.status.UnActive': 'Chưa kinh doanh',
  'voucher.status.Expiring': 'Kết thúc',
  'voucher.status.Suspended': 'Ngừng kinh doanh',
  'voucher.modal.amount.validate': 'Vui lòng nhập số tiền lớn hơn hoặc bằng 1.000 VNĐ',

  'voucher-info.title': 'Thông tin voucher',
  'voucher-info.router.name': 'Thông tin voucher',
  'voucher-info.customer.title': 'Danh sách khách hàng nhận voucher',
  'voucher-info.customer.nickName': 'Tên người dùng',
  'voucher-info.customer.phoneNumber': 'Số điện thoại ',
  'voucher-info.amount': 'Số tiền nhận được (VNĐ)',
  'voucher-info.receivingTime': 'Thời gian nhận',
  'voucher-info.receiverPhoneNumber': 'Số điện thoại nhận',
  'voucher-info.totalCustomer': 'Tổng số khách hàng',
  'voucher-info.totalReceiving': 'Số lượt nhận',
  'voucher.accessibleAgency': 'Hệ thống áp dụng',
  'voucher.accessibleAgency.aqua': 'Aquafina',
  'voucher.accessibleAgency.green': 'Greenfinity',
  'voucher.accessibleAgency.aquaAndGreen': 'Aquafina và Greenfinity',
};
