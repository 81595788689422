export default {
  'voucher.router.name': 'Voucher converted',
  'voucher.name': 'Voucher type',
  'voucher.title': 'Management of conversion voucher warehouse',
  'voucher.update': 'Update voucher',
  'voucher.create': 'Create a voucher',

  'voucher.quantity': 'Total',
  'voucher.remainingQuantity': 'Quantity purchased',
  'voucher.usedQuantity': 'Quantity used',
  'voucher.updatedAt': 'Latest update',
  'voucher.buyDeadline': 'Purchase term',
  'voucher.coin': 'Green Coin',
  'voucher.status': 'Status',
  'voucher.sortType': 'Arrangement',
  'voucher.businessStatus': 'Operating status',
  'voucher.content': 'Content',
  'voucher.delete.title': 'Delete voucher',
  'voucher.delete.content': 'You definitely want to delete this voucher?',
  'voucher.price': 'Number of conversion points',
  'voucher.amount': 'The amount received (VNĐ)',
  'voucher.modal.amount': 'The amount received',
  'voucher.modal.quantity': 'Quantity',
  'validate.dateTime': 'Please enter the purchase deadline',
  'voucher.information': 'Voucher information',
  'voucher.modal.quantity.validate': 'The amount must be greater than the amount used',

  'voucher.status.operationStatus':
    '{status,select, 1 {Available} 2 {UnActive} 3 {Expiring} 4 {Suspended} other {Unknown}}',
  'voucher.status.Available': 'Available',
  'voucher.status.UnActive': 'UnActive',
  'voucher.status.Expiring': 'Expiring',
  'voucher.status.Suspended': 'Suspended',
  'voucher.modal.amount.validate': 'Please enter the amount greater than or equal to 1,000 VNĐ',

  'voucher-info.title': 'Voucher information',
  'voucher-info.router.name': 'Voucher information',
  'voucher-info.customer.title': 'List of customers receiving vouchers',
  'voucher-info.customer.nickName': 'Username',
  'voucher-info.customer.phoneNumber': 'Phone number',
  'voucher-info.amount': 'The amount received (VND)',
  'voucher-info.receivingTime': 'Receiving time',
  'voucher-info.receiverPhoneNumber': 'Phone number received',
  'voucher-info.totalCustomer': 'Total number of customers',
  'voucher-info.totalReceiving': 'Number of turns',
  'voucher.accessibleAgency': 'Accessible agency',
  'voucher.accessibleAgency.aqua': 'Aquafina',
  'voucher.accessibleAgency.green': 'Greenfinity',
  'voucher.accessibleAgency.aquaAndGreen': 'Aquafina and Greenfinity',
};
